import { NgModule } from '@angular/core';
import { TermSearchPipe } from './term-search.pipe';
import { SafePipe } from './safe.pipe';
export const pipes = [
    TermSearchPipe,
];

@NgModule({
  declarations: [pipes, SafePipe],
  exports: [pipes,SafePipe]
})

export class PipesModule { }
