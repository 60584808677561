import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UserDTO } from '../../models/user/user.dto';
import { UserService } from '../../providers';
import { TransactionService } from '../../providers/transaction/transaction.service';

@Component({
  selector: 'app-credit-wave-account',
  templateUrl: './credit-wave-account.page.html',
  styleUrls: ['./credit-wave-account.page.scss'],
})
export class CreditWaveAccountPage implements OnInit {

  public transacNum:string = "";
  public me:any;
  displayError: boolean = false;
  constructor(private modalCtrl:ModalController, private t_service:TransactionService, private u_service:UserService, private toast_ctrl : ToastController) { }

  async ngOnInit() {
    this.me = (await (await this.u_service.getUserInfo()).toPromise() as UserDTO[])[0];
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  onSubmit(){
    let {transacNum} = this;
    this.t_service.creditAccountWithWave({
      transacNum,
      id : this.me?.profile?.id
    }).subscribe({
      next : async (data) => {
        let toast = await this.toast_ctrl.create({
          message : "Votre compte a été crédité avec succès",
          duration : 2000,
          translucent : true
        })
        toast.present()
        this.closeModal();
      },
      error : async () => {
        let toast = await this.toast_ctrl.create({
          message : `aucun transaction avec le numéro ${this.transacNum} trouvé.`,
          duration : 2000,
          translucent : true
        })
        toast.present()
        this.displayError = true;
      }
    })
  }

}
