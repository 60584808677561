import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private _user
  private _token: any;
  constructor(private _router:Router, private auth:AngularFireAuth) {
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next))
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    this._user = await this.auth.currentUser;
    if (this._user){
      if (req.url.includes(environment.API_URL))
      {
        this._token = await this._user.getIdToken();
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this._token}`
          }
        })
        return next.handle(authReq).pipe(catchError(this.errorCatcher)).toPromise()
      }
    }
    return next.handle(req).toPromise()
  }

  errorCatcher(errorResponse: HttpErrorResponse){
      // if the status is Unauthorized
      if (errorResponse.status === 401) {
        // we redirect to login
        this._router.navigate(['/auth']);
      } else {
        // else we notify the user
      }
      return throwError(errorResponse);
  }


}