// Add your providers here for easy indexing.
export { TranslateProvider } from './translate/translate.service';
export { RestaurantService } from './restaurant/restaurant.service';
export { OrdersService } from './orders/orders.service';
export { DishService } from './dish/dish.service';
export { CartService } from './cart/cart.service';
export { MessageService } from './message/message.service';
export { CategoryService } from './category/category.service';
export {UserService} from "./user/user.service";
export {GooglemapService} from "./google/googlemap.service";
export {CountryService} from "./country/country.service";
export {SoundService} from "./audio/sound.service";
