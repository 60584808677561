export class GroupDto {
    id?:number;
    name: string;
    maxUsers: number;
    restaurant:{id: number} = {id : 0};
    company:{id: number} = {id : 0};
    lunchHour:string = new Date().toISOString();
    isPrivate: boolean = false;
    password: string;
    creatorId:number;
    deliveryType:"in" | "away" | "delivery" = "delivery";
    accounts: {id:number}[] = [];
}