import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CredentialsDTO,ProfileDTO,CreateUserDTO} from "./../../models";
import { environment } from 'src/environments/environment';
import { BaseCRUDService } from '../../classes/base.crud.service';
import { UserDTO } from '../../models/user/user.dto';
import {NgxPermissionsService} from "ngx-permissions";
import { Role } from '../../enums/role';
import * as firebase from 'firebase';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { of } from 'rxjs/internal/observable/of';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseCRUDService{
  user_store:UserDTO;
  constructor(_http:HttpClient,private _permissionService:NgxPermissionsService, private _authFirebase:AngularFireAuth){
    super(_http)
    this.controllerName = "users";
    this.initRoles();
  }

  async initRoles(){
      (await this.getUserInfo()).subscribe({
        next : (data:UserDTO[]) => {
          if(data){
            let me = data[0];
            if( me?.company && me?.profile.lunchGroup && me?.profile.id === me?.profile.lunchGroup.creatorId) this._permissionService.addPermission(Role.EMPLOYEE_GROUP_ADMIN);
            else if( me?.company && me?.profile.lunchGroup && me?.profile.id !== me?.profile.lunchGroup.creatorId && me?.profile.lunchGroup.currentSessionId !== "") this._permissionService.addPermission(Role.EMPLOYEE_GROUP);
            else if( me?.company && (!me?.profile.lunchGroup || me?.profile.lunchGroup.currentSessionId === "")) this._permissionService.addPermission(Role.EMPLOYEE);
            else this._permissionService.addPermission(Role.USER);
          }
        },
        error : () => console.error
      });
  }

  async getUserInfo(){
    let current_user = await this._authFirebase.currentUser;
    if(!current_user) return of(null);
    return (current_user.phoneNumber) ? this.getDataWithQb(RequestQueryBuilder.create()
      .setJoin({field:"profile"})
      .setJoin({field : "company.address"})
      .setFilter({field:"users.uid",operator:'$eq',value:current_user.uid})
      .setLimit(1)
      .query()) : this.getDataWithQb(RequestQueryBuilder.create()
      .setJoin({field:"profile"})
      .setJoin({field : "company.address"})
      .setFilter({field:"users.profile.email",operator:'$eq',value:current_user.email})
      .setLimit(1)
      .query());
  }

  async refreshToken(token:string){
    let fuser = await this._authFirebase.currentUser, 
    uid = fuser?.uid;
    return (uid) ? this.http.post(`${environment.API_URL}/users/token/update`,{uid, token}) : of(null);
  }

  getSolde(id){
    return this.http.get(`${environment.API_URL}/users/${id}/solde`)
  }

  authentificate(credentials:CredentialsDTO):Promise<any>{
    return this.http.post(`${environment.API_URL}/auth/login/customers`,credentials).toPromise();
  }
  create(user:CreateUserDTO):Promise<any>{
    return this.http.post(`${environment.API_URL}/users/customers/create`,user).toPromise();
  }
  profile(id:string){
    return this.http.get(`${environment.API_URL}/users/${id}/profile`)
  }

  update(id:string,data:ProfileDTO){
    return this.http.put(`${environment.API_URL}/users/${id}/profile`,data);
  }
}
