import { Orientation, TourStep } from "ngx-guided-tour";

export class StepsTour {

    public static CART_TOUR:TourStep[] = [{
        title: 'Supprimer',
        selector: '#cart_item0',
        content: 'Pour supprimer le produit faire glisser vers la gauche',
        orientation: Orientation.Bottom
    }]

    public static GROUP_MENU:TourStep = {
        title : "Liste des groupes",
        selector : "#t_group_menu",
        content : "Commencer avec les commandes groupées elle vous permettrons de manger avec vos collègues dans le même restaurant et de bénéficier d'un tarif unique de 500 F sur la livraison !",
        orientation : Orientation.Top
    }
    
    public static GROUP_TOUR:TourStep[] = [{
        title : "Commande groupé",
        selector : "#t_group_order",
        content : "Pour manger avec tes collègues au bureau dans le même restaurant en bénéficiant d'un tarif de 500F sur la livraison.",
        orientation : Orientation.Bottom
    }]

    public static GROUP_SESSION_STARTED:TourStep = {
        title : "Administrateur du groupe",
        selector : "#t_group_join_admin",
        content : "Tu es administrateur du groupe avec un grand 'A' c'est donc à toi de valider la comande quand tous les collègues auront fini. ",
        orientation : Orientation.Top
    }

    public static GROUP_SESSION_STARTED_VALIDATE:TourStep = {
        title : "Validation de la commande",
        selector : "#t_group_join_admin_validation",
        content : "Pour valider toutes les commandes c'est ici ! attention si certains de tes collègue n'ont pas finalisé la commande sera envoyée quand-même envoyé au restaurant",
        orientation : Orientation.Top
    }

    public static GROUP_SESSION_STARTED_LIST:TourStep = {
        title : "Liste des membres",
        selector : "#t_group_join_list",
        content : "L'ensemble des collègues qui participe à cette commande.",
        orientation : Orientation.Top
    }

    public static GROUP_SESSION_STARTED_LEAVE:TourStep = {
        title : "Quitter le groupe",
        selector : "#t_group_join_admin_leave",
        content : "Tu peux quitter le groupe en cliquant ici !",
        orientation : Orientation.Top
    }

    public static GROUP_LIST:TourStep = {
        title : "Liste des groupes",
        selector : "#t_group_list",
        content : "Liste des groupes que tu peux rejoindre",
        orientation : Orientation.Top
    }

    public static CREATE_GROUP:TourStep = {
        title : "Créer un groupe",
        selector : "#t_group_button_create",
        content : "créer votre groupe, en le créant vous devrez démarrer la commande groupée et la valider pour les collègues du groupe",
        orientation : Orientation.Top
    }

    
    

    public static GROUP_ADMIN_TOUR:TourStep[] = [...StepsTour.GROUP_TOUR, StepsTour.GROUP_SESSION_STARTED,StepsTour.GROUP_SESSION_STARTED_LIST, StepsTour.GROUP_SESSION_STARTED_VALIDATE, StepsTour.GROUP_SESSION_STARTED_LEAVE,StepsTour.GROUP_LIST]
    public static GROUP_ADMIN_NO_GROUP:TourStep[] = [...StepsTour.GROUP_TOUR,StepsTour.GROUP_LIST];
    public static GROUP_ADMIN_NO_SESSION:TourStep[] = [...StepsTour.GROUP_TOUR]


}