import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http:HttpClient) { }
  getAll(){
    return this.http.get(`${environment.COUNTRY_API_URL}/${environment.COUNTRY_API_VERSION}/all`);
  }
  getCountryByCode(code:string){
    return this.http.get(`${environment.COUNTRY_API_URL}/${environment.COUNTRY_API_VERSION}/alpha/${code}`);
  }
  getByCodes(codes:string){
    return this.http.get(`${environment.COUNTRY_API_URL}/${environment.COUNTRY_API_VERSION}/alpha?codes=${codes}`);
  }
  getByCallingCode(phone_code:number){
    return this.http.get(`${environment.COUNTRY_API_URL}/${environment.COUNTRY_API_VERSION}/callingcode/${phone_code}`)
  }
  getByRegion(rg:String){
    return this.http.get(`${environment.COUNTRY_API_URL}/${environment.COUNTRY_API_VERSION}/region/${rg}`);
  }
}
