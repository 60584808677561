import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import restaurants from './mock-restaurants';
import { Storage } from '@ionic/storage';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { environment } from 'src/environments/environment';
import { BaseCRUDService } from '../../classes/base.crud.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService extends BaseCRUDService{
  labelAttribute = 'name';
  favoriteCounter = 0;
  favorites: Array<any> = [];
  restaurants: Array<any> = restaurants;

  constructor(private _http:HttpClient,private nativeStorage:Storage){
      super(_http);
      this.controllerName = "restaurants";
  }

  findAll() {
    return Promise.resolve(restaurants);
  }

  getRestaurants() {
    return this.restaurants;
  }
  
  getMenu(menuID:number){
    return this.http.get(`${environment.API_URL}/menu/${menuID}`)
  }

  getMenuCat(menuID:number){
    return this.http.get(`${environment.API_URL}/menu/${menuID}/categories`)
  }

  async getStorageItem(menuId):Promise<any>{
    let rest = this.getDataWithQb(RequestQueryBuilder.create().setFilter({field : "menu.id",operator : "$eq", value:menuId}).query).toPromise()
    return rest[0];
  }
  findById(id) {
    return Promise.resolve(restaurants[id - 1]);
  }
  getItem(id){
    return this.http.get(`${environment.API_URL}/restaurants/${id}`);
  }
  getResults(keyword:string){
    //if (!keyword) { return false; }
     return this.http.get(`${environment.API_URL}/restaurants/search/${keyword}`);
  }

  findByName(searchKey: string) {
    const key: string = searchKey.toUpperCase();
    return Promise.resolve(restaurants.filter((restaurant: any) =>
    (restaurant.title +  ' ' + restaurant.address +  ' ' + restaurant.city + ' ' +
    restaurant.description).toUpperCase().indexOf(key) > -1));
  }

  getFavorites() {
    return Promise.resolve(this.favorites);
  }

  favorite(restaurant) {
    this.favoriteCounter = this.favoriteCounter + 1;
    this.favorites.push({id: this.favoriteCounter, restaurant: restaurant});
    return Promise.resolve();
  }

  unfavorite(favorite) {
    const index = this.favorites.indexOf(favorite);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
    return Promise.resolve();
  }
}
