import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { environment } from 'src/environments/environment';
import { BaseCRUDService } from '../../classes/base.crud.service';
import { GroupJoinDto } from '../../dto/group-join-dto';
import { IGroupOrderDto } from '../../interfaces/i-group-order-dto';
import { IGroupValidationDto } from '../../interfaces/i-group-validation-dto';

@Injectable({
  providedIn: 'root'
})
export class GroupService extends BaseCRUDService{

  constructor(_http:HttpClient) { 
    super(_http);
    this.controllerName = "lunch-group";
  }

  join(body:GroupJoinDto){
    return this.http.post(`${environment.API_URL}/${this.controllerName}/join`, body)
  }

  leave(body){
    return this.http.post(`${environment.API_URL}/${this.controllerName}/leave`, body)
  }

  allGroups(value:number){
    return this.getDataWithQb(RequestQueryBuilder.create()
                                  .setFilter([{field : "company.id", operator : '$eq', value}])
                                  .setJoin([{field : "restaurant", select : ["name","category"]},
                                            {field : "company", select : ["id"]},
                                            {field : "accounts", select : ["id","firstname", "lastname"]}])
                                  .query())
  }

  addOrder(body:IGroupOrderDto){
    return this.http.post(`${environment.API_URL}/${this.controllerName}/order/add`, body)
  }

  validateOrder(body:IGroupValidationDto){
    return this.http.post(`${environment.API_URL}/${this.controllerName}/order/validate`, body)
  }

  deleteOrder(body:IGroupValidationDto){
    return this.http.post(`${environment.API_URL}/${this.controllerName}/order/validate`, body)
  }

  startSession(body:{id:number}){
    return this.http.post(`${environment.API_URL}/${this.controllerName}/session/start`, body) 
  }
}
