import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Storage } from '@ionic/storage';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { BaseCRUDService } from '../../classes/base.crud.service';
import { RestaurantService } from '..';
@Injectable({
  providedIn: 'root'
})
export class DishService extends BaseCRUDService{
  favoriteCounter = 0;
  favorites: Array<any> = [];
  dishes: Array<any> = [];

  constructor(_http:HttpClient,private nativeStorage: Storage, private _restaurantService:RestaurantService) { 
    super(_http);
    this.controllerName = "dishes";
  }

  findAll() {
      return this.dishes;
  }
  async allCat(endpoint:string){
    return Promise.resolve((await this.nativeStorage.get("restaurant")).menu.categoriesDishes);
  }

  findById(id) {
      return Promise.resolve(this.dishes[id - 1]);
  }

  async getItem(id:number) {
      return Promise.resolve((await this.nativeStorage.get("restaurant")).menu.dishes.find((line) => {
        return line.id == id;
      }));
  }

  getFavorites() {
    return Promise.resolve(this.favorites);
  }

  favorite(dish) {
    this.favoriteCounter = this.favoriteCounter + 1;
    // this.favoriteCounter += 1;
    this.favorites.push({ id: this.favoriteCounter, dish: dish });
    return Promise.resolve();
  }

  unfavorite(favorite) {
    const ind = this.favorites.indexOf(favorite);
    if (ind > -1) {
      this.favorites.splice(ind, 1);
    }
    return Promise.resolve();
  }

  todaySpecialFromMenuId(menuId:number){
    return this.getDataWithQb(RequestQueryBuilder.create()
                                .setFilter([{field:"menu.id", operator : "$eq", value : menuId},
                                            {field : "inStock", operator : "$eq", value : true},
                                            {field : "isTodaySpecial", operator:"$eq", value : true},
                                            {field : "specialDays", operator : "$cont", value : new Date().getDay()}])
                                .query())
  }

  
  async todaySpecial(){
      let menuList = (await this._restaurantService
                                .getDataWithQb(RequestQueryBuilder.create()
                                .setFilter({ field: 'isConnected', operator: "$eq", value: true })
                                .query()).toPromise() as any[])
                                .map((_rest:any) => _rest.menu.id);
      return this.getDataWithQb(RequestQueryBuilder.create()
                                  .setFilter([{field:"menu.id", operator : "$in", value : menuList},
                                              {field : "inStock", operator : "$eq", value : true},
                                              {field : "isTodaySpecial", operator:"$eq", value : true},
                                              {field : "specialDays", operator : "$cont", value : new Date().getDay()}])
                                  .query())
  }

}
