import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class GooglemapService {

  constructor(private http:HttpClient){}

  autocomplete(search:string):Promise<any>{
    return this.http.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json`,{params : {input:search,key:"AIzaSyC2d_kHP6ZAME5ebAIa3woVOA5IylfzaMo"}}).toPromise();
  }
}
