import { Component, ViewChild } from '@angular/core';
import { NavController, ModalController, IonItemSliding, ToastController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { BasePagePricing } from "../../classes/base.page.pricing";
import { Storage } from '@ionic/storage-angular';
import {
  DishService,
  CartService,
  OrdersService,
  UserService,
  RestaurantService,
} from '../../providers';
import { GuidedTour, GuidedTourService } from 'ngx-guided-tour';
import { environment } from 'src/environments/environment';
import { DateUtils } from '../../classes/date.utils';
import { GroupDto } from '../../dto/group-dto';
import { GroupService } from '../../providers/group/group.service';
import {NgxPermissionsService} from "ngx-permissions";
import { Role } from '../../enums/role';
import { GroupModalPage } from '../group-modal/group-modal.page';
import { DeliveryService } from '../../providers/delivery/delivery.service';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { StepsTour } from '../../config/steps.tour';
import { LunchStoreService } from '../../store/lunch-store.service';
import { AngularFireAuth } from '@angular/fire/auth';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss'],
})

export class CartPage extends BasePagePricing {
  orders: Array<any>;
  totalVal = 0;
  baseURL: string = environment.API_URL;
  newOrder: any = {
    type : "in",
    client : {
      name : ""
    }
  };
  paymethods = 'cash';
  away: boolean;
  country: any;
  restID;
  restaurant: any;
  phone;
  countries: any[] = [];
  weeksDays: string[] = ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."];
  token: any;

  public cartTour: GuidedTour = {
    tourId: 'homecart-tour',
    skipCallback: (stepSkippedOn: number) => {
      this.storage.set("cart_tuto", true);
    },
    completeCallback: () => {
      this.storage.set("cart_tuto", true);
    },
    steps: StepsTour.CART_TOUR
  };

  @ViewChild('slidingList') slidingList: IonItemSliding;
  maxDate: any;
  minDate:any = new Date().toISOString();
  mySolde:number = 0;
  pickupDate:string = new Date().toISOString();
  user: any;
  group: GroupDto;
  pickUpAdress:any;
  permissionDenied: boolean = false;
  private _deliveryFee: number = -2;
  hasLunchGroup: boolean;

  constructor(
    public ordersService: OrdersService,
    public navCtrl: NavController,
    public dishService: DishService,
    public cartService: CartService,
    public route: Router,
    public toastCtrl: ToastController,
    private modalCtrl: ModalController,
    protected storage: Storage,
    private u_service:UserService,
    private g_service:GroupService,
    private router:Router,
    private _r_service:RestaurantService,
    private _alertCtrl:AlertController,
    private _ngxPermService:NgxPermissionsService,
    private _deliveryService:DeliveryService,
    private _lunchGroupStore:LunchStoreService,
    private guideTourService: GuidedTourService,
    private _authFirebase:AngularFireAuth) {

    super(cartService, navCtrl, storage);
    this.getOrders();
  }

  isArray(ar) {
    return Array.isArray(ar);
  }

  getPhoto(id, image) {
    let img = (image && image.name) ? image.name : 'noimage.png';
    return `${environment.API_URL}/uploads/pictures/restaurants/dishes/${id}/${img}`;
  }

  async removeOrder(order) {
    this.cartService.removefromCart(order)
      .then(() => {
        this.getOrders();
      })
      .catch(error => alert(JSON.stringify(error)));

    await this.slidingList.close().then((a) => { });
  }

  async getOrders() {
    let displayTuto = await this.storage.get("cart_tuto");
    this.cartService.getOrders().then(orders => {
      this.orders = orders;
      if (this.orders.length > 0) {
        if (!displayTuto) {
          setTimeout(() => {
            this.guideTourService.startTour(this.cartTour);
          }, 1000);
        }
      }
      this.getTotal();
      
    });
  }

  getDeliveryFee(){
    if(!this.pickUpAdress || this.orders.length === 0){
      this._deliveryFee = -1;
      return;
    }

    this._ngxPermService.hasPermission(['EMPLOYEE_GROUP','EMPLOYEE_GROUP_ADMIN']).then((perm:boolean) => {
      if(perm){
        if( this._lunchGroupStore.userStates.length < 6){
          this._deliveryFee = 500;
        }else if( this._lunchGroupStore.userStates.length > 5 &&  this._lunchGroupStore.userStates.length < 10){
          this._deliveryFee = 400;

        }else{
          this._deliveryFee = 300;
        }
      }else{
        this._r_service.getDataWithQb(
          RequestQueryBuilder.create()
          .setFilter({field : "menu.id",operator : "$eq",value : this.orders[0].menu.id}).query()).subscribe((_rest:any) => {
            let size = (this.group && this._lunchGroupStore.userStates.length >= 2) ?  this._lunchGroupStore.userStates.length : 1;
            this._deliveryService.getAmount(this.pickUpAdress,size,_rest[0].address.placeId).then((amount:number) => {
              this._deliveryFee = amount;
            },(err) => {
              this._deliveryFee = -1;
            });
          });
      }
      
    })
    
  }

  getTotal(){
      this.totalVal = this.orders.reduce((total, val) => {
          return total + this.getPrice(val);
      },0);
    return this.totalVal + ((this.newOrder.deliveryType === "delivery" ) ? this._deliveryFee : 0);
  }

  async ionViewWillEnter() {
    this.away = true;
    (await this.u_service.getUserInfo()).subscribe({  
      next : async (data) => {
         if(data[0]){
          this.user = data[0];
          this.mySolde = await this.u_service.getSolde(this.user.profile.id).toPromise() as number;
          if(this.mySolde > 0) this.paymethods = "fcash";
          this.newOrder.client.name = (this.user.profile.firstname) ? `${this.user.profile.firstname} ${this.user.profile.lastname}` : '';
          this.hasLunchGroup = this.user.profile.lunchGroup !== null;
          if(this.hasLunchGroup) {
            this.newOrder.type = this.user.profile.lunchGroup.deliveryType;
            let oldDate = new Date(this.user.profile.lunchGroup.lunchHour);
            let newDate = new Date();
            newDate.setHours(oldDate.getHours(),oldDate.getMinutes())
            this.pickupDate = newDate.toISOString();
            this.paymethods = "fcash";
            this.group = await this.g_service.getOne(this.user.profile.lunchGroup.id).toPromise() as GroupDto;
          }else{
            let nDate = new Date(this.pickupDate);
            nDate.setMinutes(0);
            this.pickupDate = nDate.toISOString();
          }
         }
      },
      error : (err) => {
        console.log(err)
      }
    });

    this.orderNumber = Math.floor(Math.random() * 10000);
    this.getOrders();

     // get position
    this.getMyLocation().then((coord:any) => {
      this.pickUpAdress = coord;
      this.getDeliveryFee();

    }).catch((e) => {
        console.log(e);
    });
  }

  async locateMe(){
    this.pickUpAdress = await this.getMyLocation();
    this.getDeliveryFee();

  }

  getMyLocation():Promise<any>{
    return new Promise((resolve,reject) => {
      if('geolocation' in navigator){
        navigator.geolocation.getCurrentPosition((pos) => {
          resolve(pos.coords);
        },async (err) => {
          /*(navigator.permissions as any).revoke({name:'geolocation'}).then(function(result) {
            console.log(result.state);
          });*/
          this.newOrder.type = "away";
          this.permissionDenied = true;
          (await this._alertCtrl.create({
            header : "Activez votre GPS",
            message : "Le partage de votre position est indispensable pour la livraison.Activez votre GPS"
          })).present()
          console.log("Position denied",err);
          reject(null);
        })
      }else{
        alert("Votre navigateur ne supporte pas la géolacalisation.")
        reject(null)
      }
    })
  }


  // minus adult when click minus button
  minusQtd(order) {
    this.cartService.editQtdOrder(order, 'minus')
      .then(() => {
        this.getOrders();
      })
      .catch(error => alert(JSON.stringify(error)));
  }

  // plus adult when click plus button
  plusQtd(order) {
    this.cartService.editQtdOrder(order, 'plus')
      .then(() => {
        this.getOrders();
      })
      .catch(error => alert(JSON.stringify(error)));
  }

  async openCheckout() {
    let _user = await this._authFirebase.currentUser;
    let token = await this.storage.get("token")
    if(this.newOrder.client.name && this.newOrder.type){
      let order = {
        restaurantId: Number(this.restID),
        method: this.paymethods,
        client: this.newOrder.client.name,
        profileId : this.user?.profile.id,
        tel:  (this.user?.profile.tel) ? this.user.profile.tel : _user.phoneNumber,
        currency: "CFA",
        type: this.newOrder.type,
        pickupAddress : {},
        orderNum : Math.random().toString(8).substr(2, 7),
        datePickUpTimestamp: this.newOrder.pickup,
        dishes: this.orders,
        token,
        total: this.totalVal,
        deliveryFee : this._deliveryFee
      }

      if(this.newOrder.type === "delivery"){
        if(this.pickUpAdress){
          order.pickupAddress = {
            loc : {lat : this.pickUpAdress.latitude, lng : this.pickUpAdress.longitude},
            address : this.user.company?.address?.address
          }
        }else{
          alert("Impossible de récupérer votre position");
          return;
        }
      }
      let aloneOrder = await this._ngxPermService.hasPermission([Role.EMPLOYEE,Role.USER]);
      if(aloneOrder){
        if(this.paymethods === "fcash"){
          this.addOrderAfterSolde(order, async () => {
            this.ordersService.saveOrder([order]).subscribe((order: any) => {
              this.cartService.cleanCart();
              this.closeModal();
              this.router.navigate(['latest-orders']);
            }, async (error) => {
              let toast = await this.toastCtrl.create({
                message: 'Désolé une erreur est survenue',
                duration: 1000,
                color: "warning",
                position: 'bottom'
              });
              toast.present();
            });
          })
        }else{
          this.ordersService.saveOrder([order]).subscribe((order: any) => {
            this.cartService.cleanCart();
            this.closeModal();
            this.router.navigate(['latest-orders']);
          }, async (error) => {
            let toast = await this.toastCtrl.create({
              message: 'Désolé une erreur est survenue',
              duration: 1000,
              color: "warning",
              position: 'bottom'
            });
            toast.present();
          });
        }        
      }else{
        let isCreatorOrder = await this._ngxPermService.hasPermission(Role.EMPLOYEE_GROUP_ADMIN);
        if(this.paymethods === 'fcash'){
          this.addOrderAfterSolde(order,async () => {
            this.g_service.addOrder({
              sessionId : this.user.profile.lunchGroup.currentSessionId,
              uid : this.user.uid,
              //groupId : this.user.profile.lunchGroup.id,
              order : {...order, isCreatorOrder } 
            }).subscribe(async () => {
              (await this.toastCtrl.create({
                message: 'Votre commande a été ajoutée à la commande groupée !',
                duration: 1000,
                position: 'bottom'
              })).present();
  
              this.closeModal();
  
              this._lunchGroupStore.userStates = this._lunchGroupStore.userStates.map((memberState) => { 
                let state = (memberState.id === this.user?.profile.id) ? 2 : memberState.state;
                return {...memberState,state};
              });
              (await this.modalCtrl.create({
                component : GroupModalPage
              })).present();
  
            }, async () => {
              (await this.toastCtrl.create({
                message: 'Désolé une erreur est survenue',
                duration: 1000,
                color: "warning",
                position: 'bottom'
              })).present()
            });          
          })
        }else{
          this.g_service.addOrder({
            sessionId : this.user.profile.lunchGroup.currentSessionId,
            uid : this.user.uid,
            //groupId : this.user.profile.lunchGroup.id,
            order : {...order, isCreatorOrder } 
          }).subscribe(async () => {
            (await this.toastCtrl.create({
              message: 'Votre commande a été ajoutée à la commande groupée !',
              duration: 1000,
              position: 'bottom'
            })).present();

            this.closeModal();

            this._lunchGroupStore.userStates = this._lunchGroupStore.userStates.map((memberState) => { 
              let state = (memberState.id === this.user?.profile.id) ? 2 : memberState.state;
              return {...memberState,state};
            });
            (await this.modalCtrl.create({
              component : GroupModalPage
            })).present();

          }, async () => {
            (await this.toastCtrl.create({
              message: 'Désolé une erreur est survenue',
              duration: 1000,
              color: "warning",
              position: 'bottom'
            })).present()
          }); 
        }
        
      }
      
    }else{
      let toast = await this.toastCtrl.create({
        message: 'Renseignez toutes les informations',
        duration: 1000,
        position: 'middle'
      });
      toast.present();
    }
    
  }

  addOrderAfterSolde(order:any,action:any){
    this.u_service.getSolde(this.user.profile.id).subscribe(async (solde) => {
      if(solde >= (order.total +  (order.type === "delivery") ? order.deliveryFee : 0)){
        action();
      }else{
        (await this.toastCtrl.create({
          message: 'Désolé votre solde est insufisant',
          duration: 1000,
          position: 'bottom'
        })).present();
      }
    })
  }

  getValue(){
    return new Date().toISOString()
  }

  onDateSelect(evt){
    this.orders = this.orders.map((order:any) => {
      return {...order, pickup : new Date(evt.detail.value).getTime()}
    });
  }

  getCurrentDate() {
    return DateUtils.adjustForTimezone(new Date()).toISOString();
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

  get deliveryFee(){
    return this._deliveryFee
  }

}
