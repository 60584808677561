import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { OrderListItemComponent } from '../order-list-item/order-list-item.component';
import { ReceiptPage } from './receipt';

@NgModule({
  declarations : [ReceiptPage,OrderListItemComponent],
  imports : [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule]
})
export class ReceiptPageModule {}
