import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { WalkthroughGuard } from './shared/guard/walkthrough.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Role } from './shared/enums/role';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['search']);

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/walkthrough/walkthrough.module').then(m => m.WalkthroughPageModule),pathMatch: 'full',canActivate:[WalkthroughGuard] },
  { path: 'search', loadChildren: () => import('./pages/home-location/home-location.module').then(m => m.HomeLocationPageModule) , canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin }},
  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule), canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'history', loadChildren: () => import('./pages/account/history/dashboard.module').then(m => m.DashboardPageModule), canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'account', loadChildren: () => import('./pages/account/profile/profile.module').then(m => m.ProfilePageModule),canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'restaurant-detail/:id/:tableID', loadChildren: () => import('./pages/restaurant-detail/restaurant-detail.module').then(m => m.RestaurantDetailPageModule), canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'dish-list', loadChildren: () => import('./pages/dish-list/dish-list.module').then(m => m.DishListPageModule), canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'dish-detail/:id', loadChildren: () => import('./pages/dish-detail/dish-detail.module').then(m => m.DishDetailPageModule), canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin } },
  { path: 'latest-orders', loadChildren: () => import('./pages/latest-orders/latest-orders.module').then(m => m.LatestOrdersPageModule), canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin }},
  { path: 'cart', loadChildren: () => import('./shared/modal/cart/cart.module').then(m => m.CartPageModule), canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin }},
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule), canActivate: [AngularFireAuthGuard], data :{ authGuardPipe: redirectLoggedInToItems }},
  { path: 'scan', loadChildren: () => import('./shared/modal/scan/scan.module').then(m => m.ScanPageModule) },
  { path: 'group',loadChildren: () => import('./shared/modal/group-modal/group-modal.module').then(m => m.GroupModalPageModule), canActivate: [AngularFireAuthGuard,NgxPermissionsGuard], data :{ authGuardPipe: redirectUnauthorizedToLogin, permissions : { only : [Role.EMPLOYEE_GROUP,Role.EMPLOYEE_GROUP_ADMIN], redirectTo: '/search'} } }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
