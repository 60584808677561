import { Component, Input, OnInit } from '@angular/core';
import { NavController,AlertController, ModalController, MenuController } from '@ionic/angular';
import { environment } from './../../../environments/environment';
import { Storage } from '@ionic/storage-angular';
import {DateTime} from 'luxon';

import {
  trigger,
  style,
  animate,
  transition,
  query,
  stagger
} from '@angular/animations';
import { BasePagePricing } from 'src/app/shared/classes/base.page.pricing';
import { UserDTO } from 'src/app/shared/models/user/user.dto';
import { OrdersService, UserService, CartService } from 'src/app/shared/providers';

@Component({
  selector: 'app-latest-orders',
  templateUrl: './latest-orders.page.html',
  styleUrls: ['./latest-orders.page.scss'],
  animations: [
    trigger('staggerIn', [
      transition('* => *', [
        query(':enter', style({ opacity: 0, transform: `translate3d(100px,0,0)` }), { optional: true }),
        query(':enter', stagger('300ms', [animate('500ms', style({ opacity: 1, transform: `translate3d(0,0,0)` }))]), { optional: true })
      ])
    ])
  ]
})

export class LatestOrdersPage extends BasePagePricing implements OnInit{
  lastOrders: Array<any> = [];
  public phoneNumber:string="";
  orderByType:string="waiting";
  baseURL:string = environment.API_URL;
  now: any;
  waitingO: any[] = [];
  finishO: any[] = [];
  cancelO: any[] = [];
  constructor(
    public navCtrl: NavController,
    public ordersService: OrdersService,
    public alertController:AlertController,
    public storage:Storage,
    private modalController:ModalController,
    private u_service:UserService,
    private menu_ctrl:MenuController,
    cartService:CartService
  ) {
    
    super(cartService,navCtrl,storage);
  }

  async ngOnInit(){
    this.menu_ctrl.close();
    this.now = DateTime.fromMillis(Date.now());
    let me = (await (await this.u_service.getUserInfo()).toPromise() as UserDTO[])[0];
    this.phoneNumber = me.profile.tel;
    this.findOrders();
  }
  
  async findOrders(){
    let orders = await this.ordersService.getFromPhone(this.phoneNumber).toPromise().catch((e) => {
      console.log(e);
    }) as Array<any>;
    if(orders.length > 0){
      orders.sort((a,b) => { return (b.timestamp - a.timestamp); });
      this.lastOrders = orders;
      this.waitingO = this.waitingOrders();
      this.finishO = this.finishOrders();
      this.cancelO = this.canceldOrders();
    }
  }

  waitingOrders(){
    return this.lastOrders.filter((order) => {  
      return (order.status == "PENDING" || order.status == "PROCESSED"); 
    });
  }

  finishOrders(){
    return this.lastOrders.filter((order) => {  
      return order.status == "COMPLETE"; 
    });
  }

  canceldOrders(){
    return this.lastOrders.filter((order) => { return order.status == "CANCELED";  });;
  }

  async goToMenu(){
    let restID = await this.storage.get("restaurantID");
    let tabID = await this.storage.get("tableID");
    this.navCtrl.navigateForward(`restaurant-detail/${restID}/${tabID}`);
  }

  onOrderCancel(evt:string){
    let tempOrder = [...this.lastOrders];
    let canceledOrderIndex = this.lastOrders.findIndex((order) => order._id === evt);
    if(canceledOrderIndex > -1) this.lastOrders[canceledOrderIndex].status = "CANCELED";
    console.log(this.lastOrders[canceledOrderIndex].orderNum)
  }

  getOrders() {
    this.ordersService.getOrders()
      .then(data => {
        this.lastOrders = data;
      });
  }
  openMenu(){
    this.menu_ctrl.enable(true, 'first');
    this.menu_ctrl.open('first');
  }
}
