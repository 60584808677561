import { Injectable } from '@angular/core';{}
import {Storage} from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  orderCounter = 0;
  orders: Array<any> = [];

  constructor(private _nativeStorage:Storage){
    this.restoreCart();
  }



  addtoCart(order, qtd) {
    this.orders.push(order);
    return this.storeCart();
  }

  getOrders() {
    return Promise.resolve(this.orders);
  }

  getCartItems(){
    return this.orders.length;
  }

  removefromCart(order) {
    const index = this.orders.indexOf(order);
    if (index > -1) {
      this.orders.splice(index, 1);
    }
    return this.storeCart();
  }
  editQtdOrder(order, op) {
    for (const i in this.orders) {
      if (this.orders[i].id === order.id) {
        if (op === 'minus') {
          this.orders[i].qtd--;
          break;
        }
        if (op === 'plus') {
          this.orders[i].qtd++;
          break;
        }
      }
    }
    return this.storeCart();
  }

  async storeCart(){
    await this._nativeStorage.set("myCart",JSON.stringify(this.orders))
  }

  async restoreCart(){
    let storedCart = JSON.parse(await this._nativeStorage.get("myCart"));
    this.orders = (storedCart) ? storedCart : [];
  }
  cleanCart() {
    this.orders = [];
    return this.storeCart();
  }
}
