import { ToastController } from "@ionic/angular";

export async function presentToast(_toastController:ToastController,message){
    let toast = await _toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

   export function getDeliveryName(type:string){
    let dType:string = "";
    switch(type){
      case "delivery": dType = "Livraison";break;
      case "in": dType = "Sur place";break;
      case "away": dType = "À emporter";break;
    }
    return dType;
  }

