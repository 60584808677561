import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { environment } from 'src/environments/environment';
import _, { groupBy } from 'underscore';
import { DishService, RestaurantService } from '../../providers';
import { DistanceFromCompanyService } from '../../providers/distance/distance-from-company.service';
import { DistanceFromDistrictService } from '../../providers/distance/district/distance-from-district.service';

@Component({
  selector: 'app-dish-by-category',
  templateUrl: './dish-by-category.page.html',
  styleUrls: ['./dish-by-category.page.scss'],
})
export class DishByCategoryPage implements OnInit, AfterViewInit {
  @Input() tags: string[];
  dishesBycat: any[] = []
  distanceObject: any[] = [];
  fakeContents:any[] = [1,1,1,1,1,1];

  constructor(private _distanceFromDistrict:DistanceFromDistrictService,
              private modalCtrl: ModalController,
              private d_service: DishService,
              private ref: ChangeDetectorRef,
              private nativeStorage:Storage,
              private _restaurantService:RestaurantService,
              private _distanceFromCOmpany: DistanceFromCompanyService) {
  }

  async ionViewWillEnter() {

  }

  ngOnInit() {

  }

  async ngAfterViewInit() {
      let dishesBycat = await this.dishNotByCat() as any;
      let companyID = await this.nativeStorage.get("companyID");
      let d_id = await this.nativeStorage.get("districtId");
      this.distanceObject = (!d_id) ? await this.companyDistanceToRestaurants(companyID) : await this.districtDistanceToRestaurants(d_id);
      this.dishesBycat = dishesBycat.filter((dish) => this.distanceValue(dish.menu.id) >= 0).sort((a, b) => {
        return this.getDistance(a.menu.id) - this.getDistance(b.menu.id);
      }).slice(0,30);
      this.ref.detectChanges();
  }

  async dishNotByCat(){
    let menuList = (await this._restaurantService.getDataWithQb(RequestQueryBuilder.create()
                                                                .setFilter({ field: 'isConnected', operator: "$eq", value: true })
                                                                .query()).toPromise() as any[]).map((_rest:any) => {
                                                                  return _rest.menu.id;
                                                                });
    return this.d_service.getDataWithQb(RequestQueryBuilder.create()
      .setJoin(["tags"])
      .setJoin(["menu"])
      .setFilter([{field:"menu.id", operator : "$in", value : menuList},{ field: "tags.name", operator: "$in", value: this.tags },{ field: "isTodaySpecial", operator: "$eq", value: false }, /*{field: "menu.restaurant.isConnected", operator : "$eq", value:true}*/])
      .query()).toPromise();
  }
  districtDistanceToRestaurants(selectedDistrictId){
    return this._distanceFromDistrict.getDataWithQb(RequestQueryBuilder.create()
      .setFilter([{ field: "district.id", operator: "$eq", value: selectedDistrictId  }])
      .query())
      .toPromise() as any;
  }

  companyDistanceToRestaurants(cID){
    return this._distanceFromCOmpany.getDataWithQb(RequestQueryBuilder.create()
        .setFilter([{ field: "company.id", operator: "$eq", value: cID }])
        .query()).toPromise() as any;
  }

  getDistance(id) {
    try{
      return this.distanceObject.find((d) => {
        return d.restaurant.menu.id == id;
      }).value;
    }catch(e){
      return 0;
    }
  }

  distanceValue(id){
    return this.distanceObject?.find((d) => {
      return d.restaurant.menu.id == id;
    })?.value || -1;
  }

  getDistanceInfo(id){
    try{
      let dst = this.distanceObject.find((d) => { return d.restaurant.menu.id == id;})
      return `${dst.restaurant.name} à ${(dst.value > 1000) ? (Math.round(dst.value)/ 1000).toFixed(1)+" km" : dst.value + "m"}`;
    }catch(e) {
      return ``
    }
  }

  getDishCoverImg(dish): string {
    let image = (dish.photos[0]) ? dish.photos[0].name : 'noimage.png';
    return `${environment.API_URL}/uploads/pictures/restaurants/dishes/${dish.id}/${image}`;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
