import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { DateUtils } from '../../classes/date.utils';

@Component({
  selector: 'fo-receipt',
  templateUrl: './receipt.html',
  styleUrls: ['./receipt.scss']
})
export class ReceiptPage implements OnInit{
  data;
  dishes:any[]= [];
  shownItem: any;
  constructor(public navCtrl: NavController, public navParams: NavParams, public viewCtrl: ModalController) {
  }

  offset(){
    return DateUtils.getOffset();
  }
  
  ngOnInit(){
    this.dishes = this.data.dishes;
  }
  ionViewDidLoad() {
    console.log('ionViewDidLoad ReceiptPage');
  }
  dismiss(){
    this.viewCtrl.dismiss();
  }

}
