import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  private _audio: any = new Audio('assets/sound/juntos-607.mp3');
  constructor() { 
    
  }

  notificationSound(){
    this._audio.play();
  }
}
