import { Component, OnInit,ViewChild } from '@angular/core';
import { NavController, ModalController,IonSlides } from '@ionic/angular';
import { Router } from '@angular/router';
import {
  DishService,
  CartService,
  TranslateProvider
} from '../../providers';

@Component({
  selector: 'app-customdish',
  templateUrl: './customdish.page.html',
  styleUrls: ['./customdish.page.scss'],
})
export class CustomdishPage implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  categories: Array<any> = [
    {
      id : 1,
      name: 'Sauces',
      placeholder: 'choisir votre sauce',
      multiple:true,
      options: [{id : 1, name: "harissa"},{id : 2, name: "samouraï"}],
    },{
      id : 2,
      name: 'Cuissons',
      placeholder: 'choisir votre cuisson',
      multiple:false,
      options: [{id : 1, name: "saignant"},{id : 2, name: "à point"}],
    }];
  constructor(  public navCtrl: NavController,
    public dishService: DishService,
    public cartService: CartService,
    public route: Router,
    private modalCtrl: ModalController) { }

  ngOnInit() {
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }

  onSlideNext() {
    this.slides.slideNext(1000, false);
  }

	onSlidePrev() {
    this.slides.slidePrev(300);
  }
}
