import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ReceiptPage } from '../receipt/receipt';
import { DateUtils } from '../../classes/date.utils';
import { OrdersService, RestaurantService } from '../../providers';
import { presentToast } from '../../utils/app.utils';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  @Input() order:any;
  @Input() enabledSocket:boolean=true;
  @Input() action:any;
  @Output() onCancel:EventEmitter<string> = new EventEmitter();
  socket: Socket;

  public statusDesc:any = {
    PENDING : {
      label : "en traitement",
      color : "warning",
      progress : {
        value : 0.25,
        buffer : 0.75
      }
    },
    PROCESSED : {
      label : "en préparation",
      color : "primary",
      progress : {
        value : 0.75,
        buffer : 0.9
      }
    },
    COMPLETE : {
      label : "terminée",
      color : "success",
      progress : {
        value : 1,
        buffer : 1
      }
    },
    CANCELED : {
      label : "annulée",
      color : "danger",
      progress : {
        value : 0,
        buffer : 1
      }
    }
  }
  restaurant: any;
  constructor(public alertController:AlertController, private o_service:OrdersService, private r_service:RestaurantService, private _tCtrl:ToastController, private modalController:ModalController) { 
  }

  async presentModal(order) {
    const modal = await this.modalController.create({
      component: ReceiptPage,
      componentProps : {
        data : order
      },
      showBackdrop:true,
      backdropDismiss : true,
    });
    return await modal.present();
  }

  getDishCoverImg(dish):string{
    let image = (dish.photos[0]) ? dish.photos[0].name : 'noimage.png';
    return `${environment.API_URL}/uploads/pictures/restaurants/dishes/${dish.id}/${image}`;
  }

  ionViewWillEnter() {
    console.log(this.order);
  }

  isArray(item:any){
    return Array.isArray(item);
  }

  offset(){
    return DateUtils.getOffset();
  }

  async cancelOrder(id)
  {
    const alert = await this.alertController.create({
      header: "Annulation de la commande",
      message: "Voulez vous vraiment annuler la commande ?",
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Annulation avortée');
          }
        }, {
          text: 'Valider',
          handler: () => {
            this.o_service.cancel(id)
            .toPromise()
            .then(()=> {
              presentToast(this._tCtrl,"la commande a été annulée")
              this.onCancel.emit(id);
            }, (error) => {
              console.error("Impossible to cancel the order")
            })
          }
        }
      ]
    });

    await alert.present();
  }

  async ngOnInit() {
    this.restaurant = (await this.r_service.getOne(this.order.restaurantId).toPromise()) as any
    if(this.enabledSocket){
      this.socket = new Socket(environment.ioconfig);
      this.socket.fromEvent(`orders/${this.order._id}/status`).subscribe(async (data:any) => {
        this.order.status = data.status;
        if(data.status == "COMPLETE" || data.status == "CANCELED"){
          let alert = await this.alertController.create({
              header : "Changement de statut",
              subHeader : (data.status == "COMPLETE") ? `commande ${this.order.orderNum} prête` : `commande ${this.order.orderNum} annulée` ,
              message : (data.status == "COMPLETE") ? `Ta commande est prête tu peux venir la récupérer ! Bon appétit !` : `Désolé, mais ta commande a été annulée ... Pas de panique tu seras remboursé si tu as payé.`,
              buttons: ['OK']
          })
          await alert.present();
        }
      })
    }
  }

}
