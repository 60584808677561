import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'preview-desktop',
  templateUrl: './preview-app-desktop.component.html',
  styleUrls: ['./preview-app-desktop.component.scss']
})
export class PreviewAppDesktopComponent implements OnInit {
  @Input() link:string="";
  @Input() image:string="";
  @Input() name:string="";
  constructor() { }

  ngOnInit(): void {
  }

  getYear(){
    return new Date().getFullYear();
  }

}
