import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DateUtils } from '../../classes/date.utils';

@Component({
  selector: 'app-order-list-item',
  templateUrl: './order-list-item.component.html',
  styleUrls: ['./order-list-item.component.scss'],
})
export class OrderListItemComponent implements OnInit {
  shownItem: any;
  @Input() meal:any;
  @Input() position:number=1;

  constructor() { }

  ngOnInit() {}

  getDishCoverImg(dish):string{
    let image = (dish.photos[0]) ? dish.photos[0].name : 'noimage.png';
    return `${environment.API_URL}/uploads/pictures/restaurants/dishes/${dish.id}/${image}`;
  }
  /*
   * if given group is the selected group, deselect it
   * else, select the given group
   */
  toggleItem(item) {
    if (this.isItemShown(item)) {
      this.shownItem = null;
    } else {
      this.shownItem = item;
    }
  }
  
  isItemShown(item) {
    return this.shownItem === item;
  };

  offset(){
    return DateUtils.getOffset();
  }

  entries(meal:any):Array<Array<any>>{
    return Object.entries(meal);
  }

  isArray(item:any){
    return Array.isArray(item);
  }

}
