import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserDTO } from '../models/user/user.dto';
import { SoundService, UserService } from '../providers';
import {Storage} from "@ionic/storage-angular"
@Injectable({
  providedIn: 'root'
})
export class LunchStoreService {
  private socket: Socket;
  private _sessionId:string;
  private _initialized:boolean = false;
  private _userStates:any[] = [];
  public join:Subject<any> = new Subject();
  public leave:Subject<any> = new Subject();
  public finished:Subject<boolean> = new Subject();

  constructor(private _toastCtrl:ToastController,private _nativeStorage:Storage,private _userService:UserService, private _audioService:SoundService) { 
    this.init();
  }

  async init(){
    if(!this._initialized){
      let me = (await (await this._userService.getUserInfo()).toPromise() as UserDTO[])[0];
      if(me?.profile.lunchGroup){
  
        this.restoreGroupStates(me.profile.lunchGroup.currentSessionId);
        
        environment.ioconfig.options.query = { "groupId" : me.profile.lunchGroup.id, "profileId" : me.profile.id }
        
        this.socket = new Socket(environment.ioconfig);
        this._initialized = true;
        this._sessionId = me.profile.lunchGroup.id;

        // Évènement de démarrage de la session.
        this.socket.on(`group/${this._sessionId}/session/start`, async () => {
          this.displayToast(`La commande groupée ${me.profile.lunchGroup.name} à démarré.`);
        });
        
        this.socket.on(`group/${this._sessionId}/join`, async (data:any) => {
          let {firstname, lastname} = data;
          this.join.next(data);
          this.displayToast(`${firstname} ${lastname} à rejoint le groupe.`);
        });
        
        this.socket.on(`group/${this._sessionId}/leave`, async (data:any) => {
          let {firstname, lastname} = data;
          this.displayToast(`${firstname} ${lastname} à quitté le groupe.`);
          this.leave.next(data);
        });

        this.socket.on(`group/${this._sessionId}/validated`, async (data:any) => {
          this.displayToast(`la commande a été finalisée.`);
          this.finished.next(true);
        });

        this.socket.on(`group/${this._sessionId}/states`, async (data:any) => {
          data.forEach((userStatesSocket) => {
            let index = this._userStates.findIndex((user) => user.profile.id === userStatesSocket.profile.id);
            let {firstname, lastname} = userStatesSocket.profile;
            if(index === -1 ){
              this._userStates.push(userStatesSocket)
              this.displayToast(this.messageFromState(userStatesSocket.state,`${firstname} ${lastname}`, userStatesSocket.profile.id === me.profile.id));
            }else{
              if(userStatesSocket.state !==  this._userStates[index].state) {
                this._userStates[index].state = userStatesSocket.state;
                this.displayToast(this.messageFromState(userStatesSocket.state,`${firstname} ${lastname}`, userStatesSocket.profile.id === me.profile.id ));
              }
            }
            this._nativeStorage.set(`${me.profile.lunchGroup.currentSessionId}_session`, JSON.stringify(this._userStates))
          })
        });
      }
    }
  }

  async restoreGroupStates(sessionId){
    let storeStates = JSON.parse(await this._nativeStorage.get(`${sessionId}_session`));
    this._userStates = (storeStates) ? storeStates : [];
  }

  messageFromState(state:number,name:string,me:boolean=false){
    let msg = "";
    switch (state){
      case 0 : msg = me ? "Vous êtes en ligne" : `${name} est en ligne`; break;
      case 1 : msg = me ? "Vous avez commencé votre commande" : `${name} commande`; break;
      case 2 : msg = me ? "Vous avez validé votre commande" : `${name} a validé sa commande`; break;
    }
    return msg;
  }

  proceedOrder(profileId:number,groupId:number){
    this.socket?.emit(`order/proceed`,{ profileId, groupId});
  }

  async displayToast(message:string){

    this._audioService.notificationSound();
    
    (await this._toastCtrl.create({
      animated : true,
      duration : 1000,
      message,
      buttons : [{
        side : "start",
        icon : "people-outline"
      }]
    })).present();
  }

  flush(){
    this._initialized = false;
    //if(this.socket) this.socket?.of(`group/${this._sessionId}/join`);
  }

  get userStates(){
    return this._userStates
  }

  set userStates(value:any[]){
    this._userStates = value;
  }

  get connectedMember(){
    return this._userStates.length;
  }

  getUserState(id:number){
    return this._userStates.find((user) => {
      return user.profile.id === id;
    })?.state;
  }


}
