import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class WalkthroughGuard implements  CanActivate {
    constructor(private storage:Storage,private navController:NavController){

    }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot){
      let display = await this.storage.get("visit");
      if (display) {
        // redirect to some view explaining what happened
        
        this.navController.navigateRoot('/search');
        return false;
      } else {
        return true;
      }
    }
}
