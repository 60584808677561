import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GoogleMapService } from '../map/google-map.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  minAmount : number = environment.MIN_DELIVERY_FEE;
  constructor(private _gMapService:GoogleMapService) { 
    
  }

  getAmount(coord,groupSize,placeId="ChIJT5bln5mK5kcRBe-XwlB45_k"){
    return new Promise((resolve,reject) => {
      this._gMapService.distanceFromPlaceId(coord,placeId).then((distance) => {
        if(!distance) reject(-1);
        if(distance  < 3000) resolve(Math.max(1000/groupSize, this.minAmount));
        else if(distance > 3000 && distance < 4000 ) resolve(Math.max(Math.round(1500/groupSize), this.minAmount));
        else if(distance > 4000 && distance < 5000 ) resolve(Math.max(Math.round(2000/groupSize), this.minAmount));
        else if(distance > 5000 && distance < 6000 ) resolve(Math.max(Math.round(2500/groupSize), this.minAmount));
        else if(distance > 6000 && distance < 7000 ) resolve(Math.max(Math.round(3000/groupSize), this.minAmount));
        else resolve(Math.max(Math.round(3500/groupSize), this.minAmount));

      }).catch((err) => reject(err));
    })
  }
}
