import { NavController} from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { CartService } from '../providers';
export class BasePagePricing{
  public checkoutData: any;
  public totalVal = 0;
  public orderNumber;
  constructor(public cartService: CartService,
              public navCtrl: NavController,
              protected storage: Storage){

  }

  public getPrice(order){
    return (order.price + this.supTypeIterate(order.selectedOtptions) + this.supTypeIterate(order.selectedSupplements));
  }

  supTypeIterate(data){
    let suppPrice = 0;
    for(let supCat in data){
      if(Array.isArray(data[supCat])){
        suppPrice += this.supplementsTotal(data[supCat]);
      }else{
        suppPrice += data[supCat].price;
      }
    }
    return suppPrice;
  }


  supplementsTotal(data){
    let suppPrice = 0
    if(Array.isArray(data)){
      suppPrice += data.reduce((total,sup) => {
        return total + sup.price;
      },0);
    }else{
      suppPrice +=  data.price
    }

    return suppPrice;
  }
  

  public getOrders(){
    this.cartService.getOrders().then(async (orders) => {
      console.log(orders);
      if(orders.length == 0){
        let restID = await this.storage.get("restaurantID");
        let tabID = await this.storage.get("tableID");
        this.navCtrl.navigateForward(`restaurant-detail/${restID}/${tabID}`);
      }else{
        this.checkoutData = orders;
        this.totalVal = 0;
        this.checkoutData.forEach((val, i) => {
          this.totalVal += this.getPrice(val);
        });
        this.storage.set('order-' + this.orderNumber, this.checkoutData);
      }
    });
  }
}
