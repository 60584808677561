import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AlertController } from '@ionic/angular';
import { UserService } from './shared/providers';
import { AngularFireMessaging } from '@angular/fire/messaging'
import { AngularFireAuth } from '@angular/fire/auth';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  alert: HTMLIonAlertElement;



  constructor(private storage: Storage, public alertController: AlertController, private _userService: UserService, private _messaging: AngularFireMessaging, private _authFirebase: AngularFireAuth, private _afMessaging: AngularFireMessaging) {
    this.init()
  }

  async init() {
    //---check if push notification permission
    // has been denied by the user---
    if (Notification.permission === 'denied') return;

    this._afMessaging.requestPermission.subscribe(() => { console.log('Permission granted!'); },(error) => { console.error(error); });
    this._afMessaging.getToken.subscribe((token) => this.tokenreFresh(token),(error) => { console.error; });
    this._afMessaging.tokenChanges.subscribe((token) => this.tokenreFresh(token),(error) => { console.error; });
  }

  async tokenreFresh(token){
    if(token) {
      this.storage.set("token",token);
      (await this._userService.refreshToken(token)).subscribe((data) => {
        console.log(data);
      },error => console.error)
    }else{
      console.log("Token null");
    };
  }
  
  requestPermission() {
    return new Promise<void>((resolve, reject) => {
      if (!Notification) {
        reject("No notification");
        return;
      }
      Notification.requestPermission().then((data:any) => {
          resolve(data)
      },(err) => reject(err))
    });
  }
}