import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class OrdersService {
  orderCounter = 0;
  orders: Array<object> = [];

  constructor(private http:HttpClient){

  }
  getFromPhone(phoneNumber){
    return this.http.get(`${environment.API_URL}/orders/customers/${phoneNumber}`);
  }
  saveOrder(order) {
    //this.orderCounter = this.orderCounter + 1;
    //id restaurant + liste des plats + total + num
    this.orders.push(order);
    return this.http.post(`${environment.API_URL}/orders/request`,order);
  }
  pending(id){
    return this.http.put(`${environment.API_URL}/orders/pending`,{id});
  }
  cancel(id){
    return this.http.put(`${environment.API_URL}/orders/cancel`,{id, isCustomer : true});
  }
  getOrders() {
    console.log(JSON.stringify(this.orders))
    return Promise.resolve(this.orders);
  }
}
