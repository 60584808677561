import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';

import { Platform, NavController, AlertController, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { NotificationsService } from './notifications.service';
import { Storage } from '@ionic/storage-angular';
import { SheetState } from 'ion-bottom-sheet';
import { Socket } from 'ngx-socket-io';
import { UserDTO } from './shared/models/user/user.dto';
import { TranslateProvider, UserService } from './shared/providers';
import { SwUpdate } from '@angular/service-worker';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { Role } from './shared/enums/role';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public appPages = [];
  public isMobile: boolean = true;
  deferredPrompt: any;
  showButton: boolean = false;
  state: SheetState = SheetState.Bottom;
  topDistance: number = 200;
  socket: Socket;
  version: string = environment.version;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateProvider,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private alertCtrl: AlertController,
    private u_service:UserService,
    private storage: Storage,
    private swUpdate: SwUpdate,
    private _authFirebase:AngularFireAuth,
    private _permissionService:NgxPermissionsService,
    private _uService:UserService,
    private _firebaseAuth:AngularFireAuth,
    public navCtrl: NavController) {

    registerLocaleData(localeFr, 'fr');
    this.initializeApp();
  }

  async disconnect() {
    (await this.u_service.getUserInfo()).subscribe({
      next : async (result:UserDTO[]) => {
        let user = result[0];
        (await this.alertCtrl.create({
          header: 'Déconnexion',
          message: `Veux-tu vraiment déconnecter le numéro ${user.profile.tel} ?`,
          buttons: [
            {
              text: 'Annuler',
              role: 'cancel',
              handler: () => {
              }
            },
            {
              text: 'Valider',
              handler: () => {
                this._authFirebase.signOut().then(() => {
                  this.navCtrl.navigateRoot('auth');
                });
              }
            }
          ]
        })).present()
      }
    })

    
  }

  initializeApp() {

    this._firebaseAuth.onAuthStateChanged(() => {
      this._permissionService.flushPermissions();
      this._uService.getUserInfo().then((observable:Observable<any>) => {
        observable.subscribe({
          next : (result:UserDTO[]) => {
            if(result){
              let me = result[0]
              if( me.company && me.profile.lunchGroup && me.profile.id === me.profile.lunchGroup.creatorId) this._permissionService.addPermission(Role.EMPLOYEE_GROUP_ADMIN);
              else if( me.company && (me.profile.lunchGroup && me.profile.id !== me.profile.lunchGroup.creatorId && me.profile.lunchGroup.currentSessionId !== "")) this._permissionService.addPermission(Role.EMPLOYEE_GROUP);
              else if( me.company && (!me.profile.lunchGroup || me.profile.lunchGroup.currentSessionId === "")) this._permissionService.addPermission(Role.EMPLOYEE);
              else this._permissionService.addPermission(Role.USER);
            }else{
              console.log(null);
            }
          },
          error : (err) => {
            console.log(err);
          }
        })
      })
    })

    this.platform.ready().then(() => {
      this.isMobile = true;
      if (this.isMobile) {
        this.appPages = [
          {
            title: 'Mes commandes',
            url: '/latest-orders',
            direct: 'forward',
            icon: 'list-box'
          }, {
            title: 'Panier',
            url: '/cart',
            direct: 'forward',
            icon: 'cart'
          }];
      }

      this.statusBar.styleDefault();

      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);

      // Set language of the app.
      this.translateService.setDefaultLang(environment.language);
      this.translateService.use(environment.language);
      this.translateService.getTranslation(environment.language).subscribe(translations => {
        this.translate.setTranslations(translations);
      });
    }).catch(() => {
      // Set language of the app.
      this.translateService.setDefaultLang(environment.language);
      this.translateService.use(environment.language);
      this.translateService.getTranslation(environment.language).subscribe(translations => {
        this.translate.setTranslations(translations);
      });
    });
  }

  getCurrentState() {
    return this.state === SheetState.Top;
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  async onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    let alreadyInstalled = await this.storage.get("installed");
    this.showButton = (alreadyInstalled) ? false : true;
    if (this.showButton) this.state = SheetState.Top;
  }

  install() {
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        this.state = SheetState.Bottom;
        this.showButton = false;
        this.storage.set("installed", true);
      } else {
        //console.log('User dismissed the A2HS prompt');
      }
      this.deferredPrompt = null;
    });
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm("Une nouvelle version est disponile. mettre à jour l'application ?")) {
          window.location.reload();
        }
      });
    }
    this.storage.create();
  }
}
