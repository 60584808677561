import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDService } from '../../classes/base.crud.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseCRUDService{

  constructor(_http:HttpClient) { 
    super(_http);
    this.controllerName = "companies"
  }

}
