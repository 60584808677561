import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-word-spinner',
  templateUrl: './word-spinner.component.html',
  styleUrls: ['./word-spinner.component.scss'],
})
export class WordSpinnerComponent implements OnInit {

  constructor() { }

  initSpinnerStyles(wordSpinnerNode, words, secondsPerWord) {
    let maxCharacters = 0;
    words.forEach(wordNode => {
      let wordLength = wordNode.textContent.length;
      if (wordLength > maxCharacters) {
        maxCharacters = wordLength;
      }
      wordNode.style.animationDuration = secondsPerWord + 's';
    });
    words[0].classList.toggle('active');
    wordSpinnerNode.style.width = (maxCharacters*0.5) + 'em';
  }
  
  spinWords(wordSpinnerNode, secondsPerWord) {
    setInterval(() => {
      let activeWord = wordSpinnerNode?.querySelector('span.active');
      let nextWord = activeWord?.nextElementSibling;
      if (!nextWord)  nextWord = wordSpinnerNode?.querySelector('span:first-of-type');
      activeWord?.classList.toggle('active');
      nextWord?.classList.toggle('active');
    }, secondsPerWord*1000);
  }


  ngOnInit() {
    document.querySelectorAll('.word-spinner').forEach(wordSpinnerNode => {
      const secondsPerWord = 3;
      let words = wordSpinnerNode.querySelectorAll('span');
      if (words.length > 0) {
        this.initSpinnerStyles(wordSpinnerNode, words, secondsPerWord);
        this.spinWords(wordSpinnerNode, secondsPerWord);
      }
    });
  }

}
