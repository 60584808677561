import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseCRUDService {
    public controllerName:string;
    public http: HttpClient;

    constructor(_http:HttpClient) { 
        this.http = _http;
    }

    getTokenHeaders()
    {
        const token = localStorage.getItem('token');
        return new HttpHeaders().set('Authorization', `Bearer ${token}`);
    }

    getDataWithQb(query)
    {
        return this.http.get(`${environment.API_URL}/${this.controllerName}?${query}`);
    }

    getAll(){
        return this.http.get(`${environment.API_URL}/${this.controllerName}`);
    }

    getOne(id:any){
        return this.http.get(`${environment.API_URL}/${this.controllerName}/${id}`);
    }

    createOne(body:any){
        return this.http.post(`${environment.API_URL}/${this.controllerName}`, body)
    }

    createMany(body:any[]){
        return this.http.post(`${environment.API_URL}/${this.controllerName}/bulk`,{bulk : body});
    }

    updateOne(id:any, body:any){
        return this.http.patch(`${environment.API_URL}/${this.controllerName}/${id}`,body);
    }

    updateByUUID(uuid:string, body:any){
        return this.http.patch(`${environment.API_URL}/${this.controllerName}/${uuid}`,body);
    }

    replaceOne(id:any, body:any){
        return this.http.put(`${environment.API_URL}/${this.controllerName}/${id}`,body);
    }

    delete(id:any){
        return this.http.delete(`${environment.API_URL}/${this.controllerName}/${id}`);
    }
}