import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var google;

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {
  private _directionService: any;
  private _distanceService: any;
  constructor() {
    this._directionService = new google.maps.DirectionsService();
    this._distanceService = new google.maps.DistanceMatrixService();
  }

  distanceFromPlaceId(origin, placeId) {
    return new Promise((resolve, reject) => {
      new google.maps.places.PlacesService(new google.maps.Map(document.getElementById("map") as HTMLElement, { center: { lat: -33.866, lng: 151.196 }, zoom: 15, }
      )).getDetails({
        placeId,
        fields: ["geometry"],
      }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place && place.geometry && place.geometry.location) {
          this._distanceService.getDistanceMatrix({
            origins: [new google.maps.LatLng(origin.latitude, origin.longitude)],
            destinations: [new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng())],
            travelMode: google.maps.TravelMode.DRIVING,
            avoidHighways: true,
            avoidTolls: true,
            unitSystem: google.maps.UnitSystem.METRIC
          }, (response, status) => {
            if (status === "OK") {
              if(response.rows[0].elements[0].status === "ZERO_RESULTS"){
                reject("no results")
              }else{
                /*if(response.rows[0].elements[0].distance.value > environment.MAX_DELIVERY_DISTANCE){
                  reject("too long distance")
                }else{*/
                  resolve(response.rows[0].elements[0].distance.value);
                //}
              }
            } else {
              reject("no distance")
            }

          })
        }
      });
    })

  }
}
