import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseCRUDService } from '../../classes/base.crud.service';

interface IByDateRangeAndLimit{
  limit:number;
  startDate:Date;
  endDate: Date;
  id : number;
}

@Injectable({
  providedIn: 'root'
})
export class TransactionService extends BaseCRUDService{

  constructor(_http:HttpClient) { 
    super(_http);
    this.controllerName = "transaction";
  }
  /*{
    limit: number;
    startDate: Date;
    endDate: Date;
  }*/
  history(ob:any){
    return this.http.post(`${environment.API_URL}/${this.controllerName}/get`,ob);
  }
  companyLast(ob)
    {
        return this.http.post(`${environment.API_URL}/${this.controllerName}/company/get`,ob);
    }
    profileLast(ob)
    {
        return this.http.post(`${environment.API_URL}/${this.controllerName}/profile/get`,ob);
    }

    creditAccountWithWave(ob)
    {
        return this.http.post(`${environment.API_URL}/${this.controllerName}/profile/wave/validate`,ob);
    }

    
}
