import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { GroupModalPageRoutingModule } from './group-modal-routing.module';

import { GroupModalPage } from './group-modal.page';
import { TranslateModule } from '@ngx-translate/core';
import { IonBottomSheetModule } from 'ion-bottom-sheet';
import {NgxPermissionsModule} from "ngx-permissions";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
    NgxPermissionsModule.forRoot(),
    GroupModalPageRoutingModule,
    IonBottomSheetModule
  ],
  declarations: [GroupModalPage]
})
export class GroupModalPageModule {}
