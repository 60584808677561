export class DateUtils {

  static getOffset() {
    let offsetString = "";
    let offset = new Date().getTimezoneOffset();
    let offsetInhour = Math.abs(offset / 60);
    let hour = Math.floor(offsetInhour);
    let minutes = Number((offsetInhour - Math.floor(offsetInhour)).toFixed(1)) * 60;

    if (offset < 0) offsetString = (hour < 10) ? `+0${hour}` : `+${hour}`;
    else offsetString = (hour < 10) ? `-0${hour}` : `-${hour}`;

    if (minutes == 0) offsetString += `00`;
    else offsetString += minutes;

    return offsetString;
  }

  static slotTimeToDate(dt, slotTime) {
    let intervalOpening = this.getHMValueFromStr(slotTime);
    dt.setHours(intervalOpening.h);
    dt.setMinutes(intervalOpening.m);
    dt.setSeconds(0);
    dt.setMilliseconds(0);
    return dt;
  }

  static adjustForTimezone(date:Date):Date{
    var timeOffsetInMS:number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date;
 }

  static getHMValueFromStr(str) {
    return { h: Number(str.split(":")[0]), m: Number(str.split(":")[1]) };
  }
}