import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewAppDesktopComponent } from './preview-app-desktop.component';
import { QRCodeModule } from 'angularx-qrcode';



@NgModule({
  declarations: [
    PreviewAppDesktopComponent
  ],
  imports: [
    CommonModule,
    QRCodeModule
  ],
  exports : [PreviewAppDesktopComponent]
})
export class PreviewAppDesktopModule { }
