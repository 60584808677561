import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { SheetState } from 'ion-bottom-sheet';
import { environment } from 'src/environments/environment';
import { GroupDto } from '../../dto/group-dto';
import { UserDTO } from '../../models/user/user.dto';
import { CartService, RestaurantService, UserService } from '../../providers';
import { CompanyService } from '../../providers/company/company.service';
import { GroupService } from '../../providers/group/group.service';
import { NgxPermissionsService } from "ngx-permissions";
import { Role } from '../../enums/role';
import { LunchStoreService } from '../../store/lunch-store.service';
import { GuidedTour, GuidedTourService } from 'ngx-guided-tour';
import { StepsTour } from '../../config/steps.tour';
import { Storage } from '@ionic/storage-angular';
import { getDeliveryName } from '../../utils/app.utils';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Component({
  selector: 'app-group-modal',
  templateUrl: './group-modal.page.html',
  styleUrls: ['./group-modal.page.scss'],
})
export class GroupModalPage implements OnInit,AfterViewInit{

  options: any = {
    effect: 'flip',
    initialSlide: 0,
    slidesPerView: 2,
    autoplay: true,
    speed: 400
  }

  state: SheetState = SheetState.Bottom;
  topDistance: number = 100;
  private: boolean = false;
  avatars: any[] = new Array(40).fill(0);
  numAvatarsDisplayJoin: number = 12;
  numAvatarsDisplay: number = 9;
  newGroup: boolean = false;
  group: GroupDto = new GroupDto();
  restaurants: any[] = [];
  me: UserDTO;
  restaurant: any;
  groups: any[] = [];
  selectedGroup: GroupDto;
  password: string;
  isLoading: any = {
    groups: true,
    company: true,
    restaurants: true
  };

  public stepTour: GuidedTour = {
    tourId: 'homecart-tour',
    skipCallback: (stepSkippedOn: number) => {
      this.storage.set("group_tuto", true);
    },
    completeCallback: () => {
      this.storage.set("group_tuto", true);
    },
    steps: []
  };

  getDeliveryN(type:string){
    return getDeliveryName(type);
  }



  constructor(private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private g_service: GroupService,
    private r_service: RestaurantService,
    private c_service: CompanyService,
    private _cartService:CartService,
    private _p_serice: NgxPermissionsService,
    public _lunchGroupStore: LunchStoreService,
    private _guidedTourService:GuidedTourService,
    protected storage: Storage,
    private u_service: UserService) {
  }

  ngAfterViewInit(): void {
  }

  async ngOnInit() {
    this.me = (await (await this.u_service.getUserInfo()).toPromise())[0] as UserDTO;
    this._lunchGroupStore.init();

    this._lunchGroupStore.join.subscribe({
      next: (data) => {
        if(this.me.profile.lunchGroup?.accounts.findIndex((user) => user.id === data.id) === -1){
          this.me.profile.lunchGroup?.accounts.push(data);
          this.setGroupAdminPermission(this.me.profile.lunchGroup);
        }
      }
    })

    this._lunchGroupStore.finished.subscribe({
      next : () => {
        //this.me.profile.lunchGroup.accounts = [];
        this.me.profile.lunchGroup.currentSessionId = "";
        this._cartService.cleanCart();
      }
    })

    this._lunchGroupStore.leave.subscribe({
      next: (data) => {
        let index = this.me.profile.lunchGroup?.accounts.findIndex((user) => user.id === data.id);
        if(index !== -1) this.me.profile.lunchGroup?.accounts.splice(index,1);
        this.setGroupAdminPermission(this.me.profile.lunchGroup);
      }
    })

    this.c_service.getOne(this.me.company.id).subscribe({
      next: (data: any) => {
        this.restaurant = data;
        this.isLoading.company = false;
      }, error: (err) => {
        this.isLoading.company = false;
      }
    })

    this.r_service.getDataWithQb(RequestQueryBuilder.create().setFilter({ field: 'isConnected', operator: "$eq", value: true }).query()).subscribe({
      next: (data: any) => {
        this.restaurants = data;
        this.isLoading.restaurants = false;
      }, error: (err) => {
        this.isLoading.restaurants = false;
      }
    })

    if (!this.me.profile.lunchGroup) {
      this._lunchGroupStore.flush();
      this.g_service.allGroups(this.me.company.id).subscribe({
        next: (data: any) => {
          this.groups = data;
          this.isLoading.groups = false;
          this.tutorialDisplay();

        }, error: (err) => {
          console.log(err);
          this.isLoading.groups = false
        }
      });

    } else {
      this.g_service.getOne(this.me.profile.lunchGroup.id).subscribe({
        next: (data:any) => {
          this.me.profile.lunchGroup = data;
          this._lunchGroupStore.init();
          this.isLoading.groups = false;
          this.tutorialDisplay();
        },
        error: (err) => {
          console.log(err);
          this.isLoading.groups = false
        }
      })
    }
  }

  async tutorialDisplay(force:boolean=false){

    let isAdminGroup = await this._p_serice.hasPermission(Role.EMPLOYEE_GROUP_ADMIN);
    let isEmployeeGroup = await this._p_serice.hasPermission(Role.EMPLOYEE_GROUP);

    if(!force){
      let displayTourGroup = await this.storage.get("group_tuto");
      if(!displayTourGroup){
        this.startTuto(isAdminGroup,isEmployeeGroup)
      }
    }
    else this.startTuto(isAdminGroup,isEmployeeGroup)
    
  }

  startTuto(isAdminGroup,isEmployeeGroup){
    setTimeout(() => {
      let steps;
      if(isAdminGroup){ 
        steps = StepsTour.GROUP_ADMIN_TOUR; 
      }else if(isEmployeeGroup){ 
        steps = StepsTour.GROUP_TOUR; 
      }else{ 
        steps = [...StepsTour.GROUP_TOUR,StepsTour.CREATE_GROUP, StepsTour.GROUP_LIST]; 
      }
      this._guidedTourService.startTour({...this.stepTour, steps});
    },1000)
  }

  starOrder() {
    this.g_service.startSession({ id: this.me.profile.lunchGroup.id }).subscribe((data:any) => {
      this.me.profile.lunchGroup.currentSessionId = data.currentSessionId;
    }, (error) => {
      console.log("Errors",error)
    })
  }

  canValidateOrder(){
    return this._lunchGroupStore.userStates.length < 2 || this._lunchGroupStore.userStates.filter((member:any) => member.state === 2).length < 2
  }


  userState(id: number): number {
    return this._lunchGroupStore.getUserState(id);
  }

  getIsLoading() {
    return Object.values(this.isLoading).every((val: boolean) => val === false)
  }

  onDateSelect(evt, group) {
    console.log(evt);
    group.lunchHour = new Date(evt.detail.value).toISOString();
  }

  getDeliveryLabel(type: string) {
    switch (type) {
      case "in": return "sur place";
      case "delivery": return "livraison";
      case "inaway": return "à emporter";
      default: return "inconnu"
    }
  }

  getCoverImg(): string {
    let image = (this.restaurant?.photos[0]) ? this.restaurant.photos[0].name : 'noimage.png';
    return `${environment.API_URL}/uploads/pictures/companies/${this.restaurant?.id}/${image}`;
  }

  getValue(f: any) {
    console.log(f);
  }

  toggleNewGroup() {
    this.newGroup = true;
    this.state = SheetState.Top;
  }

  toggleJoinGroup(group: GroupDto) {
    this.selectedGroup = group;
    this.newGroup = false;
    this.state = SheetState.Top;
  }

  getState() {
    return SheetState.Top === this.state;
  }

  async validateOrder() {
    let message = "";
    if(this._lunchGroupStore.userStates.every((val) => val.state === 2)){
      message = `tous le monde a commandé ! une fois la commande validée elle ne pourra plus être modifiée.`
    }else{
      message = `certain de vos collègues n'ont pas finalisé leur commande si vous la validée leur commande ne seront pas prise en compte.`
    }
    (await this.alertCtrl.create({
      header : `Voulez-vous vraiment valider la commande ?`,
      message,
      buttons: [{
        text: "annuler",
        role: "cancel"
      }, {
        text: "valider",
        handler: () => {
          this.g_service.validateOrder({ uid: this.me.uid, sessionId: this.me.profile.lunchGroup.currentSessionId }).subscribe(async () => {
            (await this.toastCtrl.create({
              message: 'La commande groupée est validée !',
              duration: 1000,
              position: 'bottom'
            })).present();
            this._cartService.cleanCart();
            this.me.profile.lunchGroup.currentSessionId = "";
      
          }, async () => {
            (await this.toastCtrl.create({
              message: 'Une erreur est survenue !',
              duration: 1000,
              position: 'bottom'
            })).present();
          });
        }
      }]
    })).present()
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  join(group: GroupDto) {
    this.g_service.join({
      groupId: group.id,
      profileId: this.me.profile.id,
      password: this.password
    }).subscribe({
      next: (data: any) => {
        this.state = SheetState.Bottom;
        group.accounts.push(this.me.profile);
        this.me.profile.lunchGroup = group;
        this._lunchGroupStore.flush();
        this._lunchGroupStore.init();
        this._p_serice.flushPermissions();
        this.setGroupAdminPermission(group);
      },
      error: async (err) => {
        (await this.toastCtrl.create({
          header: "Erreur de connexion",
          message: (err.status === 403) ? "Mauvais mot de passe" : "Une erreur est survenue",
          duration : 2000
        })).present()
      }
    })
  }

  setGroupAdminPermission(group:GroupDto){
    if(group && group.accounts[0].id !== group.creatorId){
      this.g_service.updateOne(group.id,{creatorId:group.accounts[0].id}).subscribe(async () => {
        group.creatorId = group.accounts[0].id;
        if(group.creatorId === this.me.profile.id){
          (await this.toastCtrl.create({
            header: "Changement de rôle",
            message: "tu es devenu l'administrateur du groupe",
            duration : 2000
          })).present()
        }
        this._p_serice.addPermission((group.creatorId != this.me.profile.id) ? Role.EMPLOYEE_GROUP : Role.EMPLOYEE_GROUP_ADMIN)
        
      });
    }else{
      this._p_serice.addPermission((group?.creatorId != this.me.profile.id) ? Role.EMPLOYEE_GROUP : Role.EMPLOYEE_GROUP_ADMIN)
    }
  }

  async leaveGroup(group: GroupDto) {

    (await this.alertCtrl.create({
      header: `Voulez-vous quitter le groupe ${group.name} ?`,
      message: "",
      buttons: [{
        text: "annuler",
        role: "cancel"
      }, {
        text: "valider",
        handler: () => {
          this.g_service.leave({ profileId: this.me.profile.id }).subscribe({
            next: (data) => {
              this.me.profile.lunchGroup = null;
              this._lunchGroupStore.flush();
              this._p_serice.flushPermissions();
              this._p_serice.addPermission(Role.EMPLOYEE);
              this.setGroupAdminPermission(group);
              this.g_service.allGroups(this.me.company.id).subscribe({ next: (data: any) => { this.groups = data }, error: (err) => { console.log(err); } });
            }
          })
        }
      }]
    })).present()
  }

  toogleUpdateGroup(_group:GroupDto){
    this.group = _group;
    this.toggleNewGroup();
  }

  updateGroup(){
    this.g_service.updateOne(this.group.id,this.group).subscribe({
      next: (data) => {
        this.state = SheetState.Bottom;
        this.group = new GroupDto();
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  createGroup() {
    this.group.accounts = [{ id: this.me.profile.id }];
    this.group.creatorId = this.me.profile.id;
    this.group.company.id = this.me.company.id;
    this.g_service.createOne(this.group).subscribe({
      next: (data) => {
        this.state = SheetState.Bottom;
        this.group = new GroupDto();
        this.groups.push(data);
      },
      error: (err) => {
        console.log(err);
      }
    })
  }
}
