import 'hammerjs';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';

// Services/Providers

// Modal Pages
import { IonicStorageModule } from '@ionic/storage-angular';
// Environment
import { environment } from '../environments/environment';

// Components
import { SocketIoModule } from 'ngx-socket-io';
// Pipes

// the scanner!
import { LatestOrdersPageModule } from './pages/latest-orders/latest-orders.module';

import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';


import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import * as echarts from "echarts";
import { IonBottomSheetModule } from 'ion-bottom-sheet';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from './shared/components/components.module';
import { DishByCategoryPage } from './shared/components/dish-by-category/dish-by-category.page';
import { NotificationsComponent } from './shared/components/notifications/notifications.component';
import { PopmenuComponent } from './shared/components/popmenu/popmenu.component';
import { ReceiptPageModule } from './shared/components/receipt/receipt.module';
import { SidebarComponent } from './shared/components/shared/sidebar/sidebar.component';
import { CartPageModule } from './shared/modal/cart/cart.module';
import { CreditWaveAccountPageModule } from './shared/modal/credit-wave-account/credit-wave-account.module';
import { CustomdishPageModule } from './shared/modal/customdish/customdish.module';
import { GroupModalPageModule } from './shared/modal/group-modal/group-modal.module';
import { ImagePageModule } from './shared/modal/image/image.module';
import { ScanPageModule } from './shared/modal/scan/scan.module';
import { SearchFilterPageModule } from './shared/modal/search-filter/search-filter.module';
import { PipesModule } from './shared/pipes/pipes.module';
import { TranslateProvider, UserService, GooglemapService, RestaurantService } from './shared/providers';
import { TokenInterceptor } from './shared/providers/auth/token.interceptors';
import { TransactionService } from './shared/providers/transaction/transaction.service';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations"
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPermissionsModule } from 'ngx-permissions';
import {Drivers} from '@ionic/storage';
import { AngularFireModule } from '@angular/fire';
import { PreviewAppDesktopModule } from './pages/preview-app-desktop/preview-app-desktop.module';
// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function chartModule(): any {
  echarts
}



@NgModule({
  declarations: [AppComponent, NotificationsComponent,PopmenuComponent,SidebarComponent,DishByCategoryPage],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ComponentsModule,
    IonicModule.forRoot(environment.config),
    AppRoutingModule,
    HttpClientModule,
    ImagePageModule,
    GuidedTourModule,
    CustomdishPageModule,
    CreditWaveAccountPageModule,
    AngularFireModule.initializeApp(environment.firebase),
    CartPageModule,
    ScanPageModule,
    PreviewAppDesktopModule,
    ReceiptPageModule,
    LatestOrdersPageModule,
    NgxPermissionsModule.forRoot(),
    SocketIoModule.forRoot(environment.ioconfig),
    GroupModalPageModule,
    SearchFilterPageModule,
    IonicStorageModule.forRoot({
      storeName: '__fastorder',
      driverOrder: [Drivers.IndexedDB,Drivers.SecureStorage,Drivers.LocalStorage]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    IonBottomSheetModule,
    PipesModule,
  ],
  entryComponents: [NotificationsComponent,PopmenuComponent,DishByCategoryPage],
  providers: [
    StatusBar,
    SplashScreen,
    GuidedTourService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TranslateProvider,
    UserService,
    GooglemapService,
    TransactionService,
    RestaurantService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide : LOCALE_ID,
      useValue : 'fr'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
