import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderComponent } from './order/order.component';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './shared/footer/footer.component';
import { WordSpinnerComponent } from './word-spinner/word-spinner.component';

@NgModule({
  declarations: [OrderComponent,FooterComponent,WordSpinnerComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports  : [OrderComponent,FooterComponent,WordSpinnerComponent]
})
export class ComponentsModule { }
